@import "../../theme/maps.scss";

ion-content {
  --padding-end: 8px;
}

.self-sale-section-right,
.self-sale-card,
.self-sale-start {
  @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
    width: 100%;
  }

  width: 65%;

  ion-button.main-button.back-button {
    letter-spacing: normal;
    font-size: 16px;
    font-size: 16px;
    height: 48px;
    width: 151px;
    margin: 15px 0 0;
  }
}

.self-sale-start {
  ion-img {

    &::part(image) {
      border-radius: 0 !important;
    }
  }

  .main-button.fixed {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    --border-radius: 0;
    color: #16254f;
    height: 42px;
    text-decoration: underline;
    font-weight: normal;

    &::part(native) {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  p,
  .terms-div,
  li {
    color: #16254f;
  }

  .xl-p {
    margin: 12px 0;
  }

  .terms-div {
    display: flex;
    justify-content: center;

    .terms-link-btn {
      margin: unset;
      width: unset;
      height: unset;
      font-size: 14px;
      --padding-start: 3px;
      --padding-end: 0;
      text-decoration: underline;
    }
  }

  .main-oval-button {
    font-size: 16px;
    margin: 20px 0;
    --border-radius: 50px;
    color: #16254f;
    height: 42px;
  }

  .youtube-div {
    position: relative;
  }

  .youtube-link-btn {
    position: absolute;
    top: calc(50% - 30px);
    background: rgba(255, 255, 255, 0.8);
    right: calc(50% - 50px);
    padding: 4px 10px;
    border-radius: 8px;

    p {
      color: red;
      font-weight: bold;
    }
  }

  .section-list {
    font-size: 16px;
    padding-right: 14%;
    text-align: right;
    padding-inline-start: 40px;
    padding-inline-end: 12px;

    li {
      width: fit-content;
    }
  }

  .logos-div {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
    direction: ltr;

    div {
      width: 25%;
    }

    ion-img {
      width: fit-content;
      margin: 5px auto;
    }
  }

  .hamlatza-div {
    padding: 60px 0 30px;
    background: #ededed;
    margin: 30px 0;

    .hamlatza-text {
      padding: 0px 65px;
      position: relative;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        padding: 0px;
      }
    }

    p {
      font-size: 16px;
      position: relative;
      z-index: 10;
      margin-bottom: 20px;
    }

    .quotes {
      height: 50px;
      width: 65px;
      z-index: 5;
      position: absolute;
      top: -27px;
      right: 10px;
    }
  }

  .google-logos-div {
    direction: ltr;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-img {
      width: fit-content;
      margin: 5px;

      &::part(image) {
        border-radius: 0 !important;
      }
    }

  }

  .packs-list {
    display: flex;
    margin: 30px 15px 45px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      display: flex;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* Internet Explorer/Edge */

      &::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, Opera */
      }
    }

    .packs-list-item {
      border: 1px solid #D7D7D7;
      border-radius: 8px;
      margin: 5px;
      padding: 20px 8px;
      position: relative;
      width: 33%;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        min-width: 200px;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        height: 6px;
        width: 100%;
        border-bottom-right-radius: inherit;
        border-bottom-left-radius: inherit;
      }

      &:nth-child(1) {
        &:after {
          background: linear-gradient(to right, #37D3CA, #99E9A1);
        }
      }

      &:nth-child(2) {
        &:after {
          background: linear-gradient(to right, #42A3FF, #90EBFF);
        }
      }

      &:nth-child(3) {
        &:after {
          background: linear-gradient(to right, #FF8C4A, #FFB643);
        }
      }
    }

    .self-sale-text-azure {
      margin: 8px auto;
    }

    .large-p {
      margin: 12px auto;
    }
  }

  .FAQ-div {
    background: #ededed;
    text-align: center;
    padding: 25px 8px 30px;

    h2 {
      margin-bottom: 12px;
    }

    .FAQ-list {
      background: white;
      padding: 15px;
      text-align: right;
      border-radius: 8px;
    }

    .FAQ-list-item {
      padding: 5px;
      border-bottom: 1px solid #d7d7d7;
    }

    .FAQ-list-item-hidden {

      transition: .3s opacity;
      transform: translate3d(0, 0, 0);
    }
  }

  .reasons-div {
    margin: 20px auto;

    .reason-slide {
      margin-bottom: 40px;
      padding: 0 40px;
    }

    .reason-slide-name {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }

  .last-div {
    background: #ededed;
    padding: 20px;

    h2 {
      margin-bottom: 12px;
    }
  }
}

.self-sale-card,
.self-sale-start {
  overflow: inherit;
  padding: 20px 20px 30px;
  color: #000000;
  min-height: 465px;

  &.self-sale-card-sm {
    min-height: unset;
  }

  .self-sale-text-azure {
    color: #42a3ff;
  }

  .self-sale-text-blue {
    color: #16254f;
  }

  .self-sale-text-gray {
    color: #383b41;
  }

  .self-sale-f18 {
    font-size: 18px;
  }

  .self-sale-f16 {
    font-size: 16px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 14px !important;
    }
  }

  .self-sale-f20 {
    font-size: 20px;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 18px !important;
    }
  }

  .self-sale-f24 {
    font-size: 24px !important;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      font-size: 24px !important;
    }
  }

  .self-sale-blue-header {
    text-align: center;
    font-size: 24px !important;
    font-weight: bold;
    line-height: 1.29;
    color: #42a3ff;
  }

  .self-sale-blue-title-lg {
    font-size: 22px;
    color: #42a3ff;
    margin-bottom: 5px !important;
  }

  .self-sale-blue-title-md {
    font-size: 20px;
    color: #42a3ff;
    margin-bottom: 10px !important;
  }

  .self-sale-section-title {
    font-size: 20px;
    font-weight: bold;
    color: #383b41;
    margin-bottom: 10px !important;
    // display: flex;
    align-items: center;
  }

  .self-sale-section-text {
    font-size: 16px;
    color: #000000;
    margin-bottom: 17px !important;
  }

  .self-sale-section-subtitle {
    color: #000000;
    font-weight: bold;
    margin-bottom: 6px !important;
    font-size: 16px;

    &.google-ads {
      margin-bottom: 16px !important;
    }
  }


  .custom-text-input {
    ion-item {
      border-radius: 5px;
      border: solid 1px #16254f;
      background-color: #fff;
      --padding-start: 11px;

      &::part(native) {
        background-color: inherit;
        min-height: 50px;
      }

      .native-textarea.sc-ion-textarea-md {
        --padding-inline-end: 0 !important;
        --padding-end: 0 !important;
        --padding-bottom: 0 !important;
      }
    }

    .input-counter {
      font-size: 13px;
      text-align: left;
    }
  }

  ion-checkbox {
    --border-color-checked: #4878FD;
    --checkmark-color: white;
    --background-checked: #4878FD;
    --border-color: #4878FD;
  }

  .custom-chip {
    background: transparent;
    color: #4878fd;
    font-size: 16px;

    ion-icon {
      height: 14px;
    }

    &.hidden {
      display: none;
    }

    &.removed {
      text-decoration: line-through;

      ion-icon {
        height: 20px;
      }
    }
  }

  ion-item {
    --min-height: unset;
    font-size: 16px;
  }

  ion-checkbox,
  ion-label,
  ion-radio {
    margin: 8px 0;
  }

  &.self-sale-card-mobile {
    .custom-chip {
      font-size: 14px;
    }

    .self-sale-section-title {
      font-size: 16px !important;
      margin-bottom: 0px !important;
    }

    .self-sale-section-text {
      font-size: 14px;
      margin-bottom: 12px !important;
    }

    .self-sale-section-subtitle {
      font-size: 14px;
      color: #000000;
      font-weight: bold;
      margin-bottom: 0px !important;
    }

    ion-item {
      font-size: 14px;
    }

    .custom-text-input {
      width: 100%;
    }
  }
}

.self-sale-start {
  padding: 15px 0 !important;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;

  .pb-control {
    height: 40px;
    font-weight: bold;
    font-size: 15px;

    &:first-child {
      font-weight: normal;

      @media (max-width: map-get($grid-breakpoints, sm)) {
        margin-left: unset;
      }
    }

    &:last-child {

      @media (max-width: map-get($grid-breakpoints, sm)) {
        margin-right: unset;
      }
    }

    &.pb-control-last {
      color: white;
      background-color: #ff4960;
      border-radius: 8px;

      &:hover {
        background-color: #ff4960;
      }
    }

    &.pb-control-continue {
      color: #ff4960;
    }

    &:hover {
      background-color: unset;
      --background-hover: unset;
    }
  }

  .pb-phase {
    background-color: #d8d8d8;
    margin: 2px;
    min-width: 26.7px;
    width: 26.7px;
    height: 2px;

    &.pb-phase-current {
      background-color: #16254f;
    }
  }
}

.custom-radio-group {
  display: flex;
  align-items: center;

  ion-item {
    font-size: 16px;
    margin: 0 0 0 10px;
  }

  &.column {
    flex-direction: column;
    align-items: flex-start;
  }

  &.mobile-view {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    opacity: inherit;

    ion-item {
      font-size: 14px;
    }
  }
}

.category-card {
  ion-checkbox {
    margin: 13px 8px;
  }
}

.category-card-header {
  display: flex;
  align-items: center;

  ion-button {
    --background-hover: inherit;
  }
}

.selected-items {
  p {
    margin-bottom: 12px;
  }
}

.terms-checkbox {
  ion-label {
    display: flex !important;
  }

  ion-button {
    --background-hover: inherit;
    --padding-bottom: 0;
    height: inherit;
    margin: 0 6px;
    font-size: inherit;
    line-height: inherit;
    width: unset;
    --border-width: 0;
    min-width: unset;
    --padding-start: 0;
    --padding-end: 0;
    border-bottom: 1px solid;
    color: #4878FD;
  }
}

.category-adv-list-wrapper {
  margin-top: 20px;

  .category-adv-list {
    border-top: 1px solid #d7d7d7;
    margin-bottom: 8px;
    padding-top: 8px;

    &:last-child {
      border-bottom: 1px solid #d7d7d7;
      padding-bottom: 8px;
    }
  }

}

.category-adv-list-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
}

.category-adv-list-opt {
  display: flex;
  flex-wrap: wrap;

  ion-item {
    width: 50%;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
    }
  }
}

.category-adgroups-list {
  display: flex;
  flex-wrap: wrap;

  ion-item {
    width: 50%;

    @media (max-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
    }
  }
}

.section-item-fixed {
  position: absolute;
  top: 90px;
  left: 0;
  width: 310px;

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }
  }

  ion-img {
    margin: 24px 20px;
  }

  ion-button {
    font-size: 16px;
    font-weight: bold;
    height: 44px;
    --border-radius: 10px;
    --box-shadow: unset;

    img {
      margin-left: 5px;
    }
  }

  ion-label {
    margin: 25px 0 22px;
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  //   @media screen and (max-width: map-get($grid-breakpoints, lg)) {
  //     display: none;
  // }   
}

.divider-h {
  border: solid 1px #dad8d8;
  margin: 11.5px 3px;
}

.self-sale-payment-card {
  .item .sc-ion-label-md-h {
    font-size: 15px;
  }
}

.item-input.input-item-bordered,
.input-item.input-item-bordered,
.item-select.input-item-bordered,
.input-item.input-item-bordered.item-has-value {
  border-radius: 5px;

  &::part(native) {
    border-radius: 5px;
    border: solid 1px #16254f;
    --ion-color-base: white !important;
  }

  .native-input {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.item .sc-ion-label-md-h {
  white-space: nowrap;
}

.lp-card {
  .self-sale-section-text {
    margin-bottom: 30px !important;
  }

  .self-sale-section-subtitle {
    margin-bottom: 8px !important;
  }

  .item-select {
    max-width: 330px;

    ion-label {
      margin-right: 10px;
    }

    ion-select {
      padding-right: 10px;
      width: 100%;

      &::part(icon) {
        top: unset;
      }

      &::part(placeholder) {
        color: black;
        opacity: 1;
      }
    }
  }


}

[aria-checked=true].sc-ion-alert-md .alert-checkbox-icon.sc-ion-alert-md {
  border-color: #4878FD;
  background-color: #4878FD;
}

.alert-checkbox-icon.sc-ion-alert-md {
  border-color: #4878FD;
}

ion-radio {
  &::part(container) {
    border-color: #4878FD;
  }

  &::part(mark) {
    background: #4878FD;
  }
}

ion-button.terms-button {
  --background-hover: inherit;
  --padding-bottom: 0;
  height: inherit;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  width: unset;
  --border-width: 0;
  min-width: unset;
  --padding-start: 4px;
  --padding-end: 4px;
  border-bottom: 1px solid;
  color: #4878FD;
}

ion-popover.select-popover.custom-select-popover .popover-content {
  margin: 10px 0px 0 5px !important;
}

.self-sale-popup {
  .modal-wrapper {
    height: 484px;
    --border-radius: 24px;
    @media (max-width: map-get($grid-breakpoints, sm)) {
      height: 635px;
    }
  }

  .self-sale-popup-content {
    padding: 35px;
    color: #16254f;

    li {
      margin-bottom: 25px;
    }
  }

  h3 {
    color: #16254f;
    font-size: 28px !important;
    font-weight: 700;
    text-align: center;
    margin: 30px 0 40px;
  }

  ion-button:first-child {
    position: absolute;
    left: 0;
    top: 10px;

    ion-icon {
      width: 40px;
      height: 40px;
    }
  }
}