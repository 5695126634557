.facebook-intro-page {
  padding: 2em 1em;
  color: #16254f;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  .icon {
    width: 3rem;
    height: 3rem;
    color: #1f5699;
    margin: auto;
    display: block;
  }

  .title {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.43;
  }

  .paragraph {
    font-size: 1.125rem;
    line-height: 1.44;
  }
}
