.image-actions-wrapper{
    position: absolute;
    bottom: 0;
    display: flex;
    right: 0px;
    align-items: center;
  
    ion-button{
      margin: 0;
      --padding-start: 11px;
      --padding-end: 11px; 
  
      ion-icon{
        font-size: 20px;
      }
  
      &.delete-btn{
          ion-icon{
          font-size: 24px;
        }
      }
    }
  }

  .image-actions-wrapper:after {
    content: "";
    display: block;
    height: 90px;
    width: 100%;
    box-shadow: inset 0px -78px 44px -44px rgba(0, 0, 0, 0.54);
    position: absolute;
    bottom: -5px;
    right: -5px;
    z-index: -1;
}

.image-actions-wrapper{
    z-index: 1;
    width: 100%;
    bottom: 5px;
    right: 5px;
}


.no-items-wraper{
    ion-img{
      width: 224px;
      height:224px;
      margin: auto auto 40px;
  
      @media (max-width: map-get($grid-breakpoints, md)) {
        width: 112px;
        height:112px;
      }
    }
}

.image-container{ 
    margin: 0 5px;
    position: relative;
    ion-img{
        height: 150px;
        object-fit: cover;
        cursor: pointer;
    }
}


ion-img::part(image){
    border-radius: 10px;
}

.selected-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    background-color: black;
    opacity: 0.5;
  }