ion-list.inbox-list-wrapper {
  .pic-checkbox {
    right: 18px;
    top: 15px;
    --border-color: #4878fd;
  }
}

ion-item.inbox-item {
  border: 1px solid white;
  cursor: pointer;
  ion-col {
    padding: 0;
  }
  ion-label {
    margin-right: 35px;
  }
  ion-icon {
    position: absolute;
    top: 15px;

    &.icon-transparent {
      opacity: 0;
    }
  }

  &.selected {
    border: 1px solid #fa016d;
    box-sizing: border-box;
  }
  &.inbox-item-unread{
    font-weight: bold;
  }
}

.news-chip-wrapper {
  ion-chip {
    background: var(--inbox-button-background);
    color: white;

    ion-icon {
      color: white;
    }
  }
}
