@import "../../theme/maps.scss";

.pnia-box-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .pnia-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;

    h2,
    h3 {
      margin: unset;
    }

    textarea {
      min-width: 90%;
      max-width: 90%;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 10px;
      resize: none;

      &:focus {
        outline: none;
        border-color: none;
        box-shadow: none;
      }
    }

    .character-couner {
      display: flex;
      justify-content: end;
      width: 90%;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.fb-stat-cube-wrapper {
  background-color: #f9f9f9;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-clip: padding-box;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 26px;
  min-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
  }

  .fb-stat-cube {
    width: calc(((100% - 20px) / 3) - 20px);
    border: 1px solid #e8e8e8;
    background-color: white;
    margin: 10px;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    :first-child {
      text-align: center;
    }
  }

  .graph {
    margin-top: 10px;
    min-height: 340px;
    width: 100%;
  }

  .permence-tables {
    border: 1px solid #e8e8e8;
    background-color: white;
    width: 100%;

    .tab-headers {
      padding: 10px 7px 0px;
      margin-bottom: 10px;
      height: 40px;

      ul {
        display: flex;
        gap: 15px;
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          cursor: pointer;
          padding: 6px 15px;
          position: relative;

          &:hover {
            background-color: #efefef;
            color: #979797;
          }

          &.active {
            &::after {
              content: "";
              display: block;
              background: linear-gradient(142deg, #42a3ff 0%, #90ebff 100%);
              height: 5px;
              bottom: 2px;
              width: 100%;
            }
          }
        }

        @media (max-width: 768px) {
          li {
            padding: 6px 0;
          }
        }
      }
    }
  }

  .leads-wapper {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    h2 {
      margin-right: 18px;
    }
  }
}

.chart-container {
  width: 100%;
  height: 100%;
}

.radio-wrapper {
  display: flex;
  margin-bottom: 10px;

  label {
    margin-left: 15px;
    display: flex;
    input {
      margin-left: 5px;
      color: #16254f;
    }
  }
}

.modal-stat {
  .modal-wrapper {
    width: 502px;
    height: 600px;
    border-radius: 8px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
      height: 456px;
    }

    .page-title-wraper {
      margin-left: 0px;
      margin-bottom: 2px;
    }

    h1 {
      text-align: center;
    }

    iframe {
      width: 400px;
      height: 600px;
    }
  }
}

.modal-share {
  .modal-wrapper {
    width: 400px;
    height: 400px;
    border-radius: 8px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }

    .page-title-wraper {
      margin-left: 0px;
      margin-bottom: 2px;
    }

    h1 {
      text-align: center;
    }

    .share-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: start;
      padding-inline: 10px;

      ion-button {
        width: 140px;
      }

      input {
        height: 36px;
        border-radius: 5px;
        border: 1px solid;
      }

      .share-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .error {
        width: 100%;
        display: flex;
        justify-content: end;
        color: red;
        margin-top: -10px;
      }
    }
  }
}

.image-replacement-tab {
  background-color: white;

  .description {
    margin-bottom: 30px;
    padding: 6px 2px 20px 36px;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    line-height: 28px;
    color: #6a7184;
  }

  .images-list {
    margin-left: 20px;

    li {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 0;
      border-width: 70%;
      list-style: none;

      &::after {
        content: "";
        background: #ccc;
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 70%;
      }

      &:first-child {
        &::before {
          content: "";
          background: #ccc;
          position: absolute;
          top: 0;
          height: 1px;
          width: 70%;
        }
      }

      img {
        width: 100px;
        height: 100px;
      }
    }
  }

  .image-input-group {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 20px;

    label {
      font-weight: bold;
      font-size: 16px;
      min-width: 25px;
    }

    input {
      width: 100%;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #9b9b9b;
      background: #fff;
    }

    .input-counter {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }

  .image-button-group {
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;

    ion-button {
      width: 80px;
    }
  }

  .image-replacement-structure {
    display: flex;
    gap: 50px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }
}

// ion-segment-button {
//   max-width: unset !important;
// }
