@import "../../theme/maps.scss";

.big-text {
  font-size: 24px;
}

.image-preview-badge {
  font-size: 16px;
  font-weight: inherit;
}

ion-list.list-md,
ion-list.list-ios {
  background: none;
}
ion-list.list-md {
  margin: 0 !important;
}
ion-card {
  &.member-detail {
    padding-left: 30px;

    ion-label {
      margin: 0;
      --min-height: unset;
    }

    ion-item {
      border-radius: 0;
      align-items: baseline;
    }

    .item-lines-inset {
      ion-label {
        margin: 0px 0 16px 21px;
      }
      ion-icon {
        top: 0 !important;
      }
      ion-button {
        top: 6px;
      }
    }

    ion-card-content {
      margin-right: 45px;
      padding-inline-start: 0;
      color: black;
      padding: 8px 0;
    }
    ion-icon {
      margin: 0 0 0 21px;
    }

    ion-button.label-button ion-icon {
      margin-left: 0px;
      padding-left: 5px;
      position: relative;
      top: -4px;
    }
    ion-button.label-button::part(native) {
      padding-inline-start: 0;
      padding-inline-end: 0;
    }
    ion-item::part(item-native) {
      padding-inline-end: 0;
    }
    ion-item {
      --min-height: 0;
    }
  }
  &.read-only {
    background: none;
    box-shadow: none;
    padding: 0;
    margin-bottom: 15px !important;

    &:first-of-type {
      margin-top: 5px;
    }

    ion-item::part(native) {
      background: unset;
    }
    .title-content {
      font-weight: normal;
      margin-right: 5px;
    }
  }
  &.hide {
    display: none;
  }
}

ion-segment-button.segment-button-checked .segment:after {
  content: "";
  position: absolute;
  display: block;
  background: var(--business-background);
  height: 5px;
  margin-top: 3px;
  width: 30px;
}

.image-desc {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 88%;
}
.open-hours-card-table tr td{
  text-align: right;
}
.open-hours-card-table tr td:not(:first-of-type) {
  margin-right: 20px;
  display: inline-block;
}

.publication-days-card-table tr td:not(:first-of-type) {
  margin-right: 20px;
  display: inline-block;
}

.toggele-item-list {
  padding: 0;
  .toggle-item {
    width: 60%;
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 48%;
    }
  }
}

.input-row-group {
  display: flex;
  justify-content: space-between;

  ion-item {
    flex-basis: 48%;
  }
}

.btn-logo-auto {
  margin-top: 14px !important;
}
.btn-logo-img {
  margin-top: 20px !important;
}
.btn-logo-gmb {
  margin-top: 0px !important;
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-top: 10px !important;
  }
}

.dvColorPickers {
  margin: auto;
  width: 220px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-bottom: 20px;
  }
  ion-col {
    padding-bottom: 0;
    &:nth-child(1) {
      border-left: solid 1px #cbcbcb;
    }
    &:nth-child(2) {
      border-right: solid 1px #cbcbcb;
    }
  }

  .lbl-logo-color {
    font-weight: bold;
    color: var(--ion-color-primary);
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 14px;
    }
    .btn-logo-color {
      border: 1px solid var(--ion-color-primary);
      border-radius: 12px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 48px;
      height: 48px;
      padding: 6px;

      --padding-end: 0;
      --padding-start: 0;
      --border-radius: 9px;
      --background: transparent;

      .btn-logo-color-inner {
        border-radius: 9px;
        width: 36px;
        height: 36px;
      }
    }
  }
}
.auto-logo-cont {
  width: 170px;
  height: 170px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: content-box;
  text-align: center;
  background-color: lightgreen;
  word-wrap: normal;
  position: absolute;
  visibility: hidden;

  .text-fitter {
    font-family: Arial;
    font-weight: bold;
  }
}
.auto-logo-prime-cont {
  direction: rtl;
  width: 200px;
  height: 200px;
  line-height: normal;
  border: none;
  text-align: center;
  font-family: Arial;
  position: absolute;
  visibility: hidden;
  .img-cont {
    box-sizing: content-box;
  }
}
.auto-logo-preview {
  font-family: Arial;
  font-weight: bold;
  border-radius: 8px;
  width: 200px;
  height: 200px;
  margin: auto;
  padding: 15px;
}
.dvTempLogo {
  margin: auto;
  width: 160px;
  height: 160px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: map-get($grid-breakpoints, md)) {
    width: 150px;
    height: 150px;
    margin: 10px auto 20px;
  }
  img {
    width: 100%;
  }
}
