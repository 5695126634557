.pdf {
  position: absolute;
  font-size: 2em;
  width: 1000px;
  height: 120px;
  z-index: -9999;
  direction: rtl;
  text-align: center;

  img {
    height: 100%;
    margin-left: 24px;
    vertical-align: top;
  }
  span {
    max-width: 600px;
    vertical-align: super;
    line-height: 120px;
  }
}

.qrIamge {
  position: absolute;
  right: -2480px;
}

.qrImageWraper {
  position: absolute;
  background-image: url(../images/backgrounds/qrPdfBg.jpg);
  width: 2480px;
  height: 3508px;
  background-size: cover;

  #downloadToPdf {
    z-index: 10000 !important;
    top: 850px;
    right: 0;
    left: 0;
    margin: auto;
    width: 1050px !important;
    height: 1050px !important;

    .qr-logo {
      position: absolute;
      z-index: 100001;
      right: 0;
      left: 0;
      top: 0;
      margin: auto;
      width: 440px;
      height: 440px;
      background-size: contain;
      bottom: 0;
    }

    svg {
      width: 1050px;
      height: 1050px;
    }
  }

  #bussinesDataContainer {
    bottom: 18%;
    font-size: 100px;
    margin: auto;
    left: 0;
    right: 0;
    width: 100%;
    z-index: unset;

    span {
      max-width: unset;
    }
  }
}
