@import "../../theme/maps.scss";

.welcomeNewUser{
    .modal-wrapper{
        width: 502px;
        height: 560px;
        border-radius: 8px;

        @media (max-width: map-get($grid-breakpoints, md)) {
            width: 302px;
            height: 456px;
        }
    }

    .page-title-wraper{
        margin-left: 0px;
    }

    h1 {
        font-size: 36px;
        font-weight: normal!important;
        text-align: center;
        margin-bottom: 0!important;

        @media (max-width: map-get($grid-breakpoints, md)) {
            font-size: 1.7rem;
        }
    }
    h1.modal-subtitle {
        font-size: 20px!important;
        @media (max-width: map-get($grid-breakpoints, md)) {
            font-size: 0.91rem!important;
            display: block;
            padding: 0 10px;
        }
    }

    ion-row{
        justify-content: center!important;
    }
    img,
    ion-img{
        position: relative;
        bottom: 66px;
        z-index: -1;
    }

    .actions-wrapper{
        position: absolute;
        bottom: 0;
    }

    .button-pop{
        font-size: 18px;
        margin-bottom: 15px;

        @media (max-width: map-get($grid-breakpoints, md)) {
            font-size: 0.91rem;
            position: inherit;
            direction: rtl;
            width: 242px;
        }
        span{
            margin-right: 28px;
        }

        ion-icon{
            width: 8px;
            height: 14px;
            margin-right: 32px;
        }
    }

    .link-outside{
        font-weight: bold;
        margin: 0 5px;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
        ion-header{
            padding-inline-start: 0!important;
            padding-inline-end:0;
            padding-top: 0!important;
        }

        ion-content{
            --padding-start: 0px;
            --padding-end: 0px; 
            --overflow: unset;
        }

        ion-toolbar{
            height: 40px!important;
        }

        .scroll-y{
            overflow-y: hidden!important;
        }

        ion-col{
            width: 100%!important;
            flex: unset!important;
            max-width: unset!important;
        }
    }

}