.Toastify__toast-container{
    left: 0;
    right: 0;
    bottom: 13px;
    margin: auto;
    transform: translateX(0%);
    width: var(--ion-grid-width-lg, var(--ion-grid-width, 720px));
  
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: calc(100% - 32px);
    }
  
    .Toastify__toast{
      padding:var(--ion-padding, 16px);
      border-radius: 8px;
      background-color: white;
      color: black;
      display: flex;
      align-items:center;
  
      &.Toastify__toast--success{
        .Toastify__toast-body:before{
            background-image: url("../images/icons/upload-check.svg");   
        }
      }
    
      &.Toastify__toast--warning{
        .Toastify__toast-body:before{
            background-image: url("../images/icons/warning.svg");   
        }
      }
    
      &.Toastify__toast--error{
        .Toastify__toast-body:before{
            background-image: url("../images/icons/error.svg");   
        }
      }
      ion-button{
        --color:var(--popover-button);
      }
  
      .Toastify__toast-body{
        display: flex;
        align-items:center;
        &:before{
          content: "";
          display:inline-block;
          margin-left: 10px;
          width: 20px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }