
.customer-logo-wrapper-mobile  {
    align-items: center;
    display: flex;
    flex-direction: column;
    p{
        color:white;
        font-size: 10px;
    }
}


.item .sc-ion-label-md-h{
    white-space: initial;
}

.missing-info-card{
    .card-desc{
        margin: 6px 0 15px;
        color: black;
    }
}

.section-item-logo{
    height: 221px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2370AEFFFF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-linecap='square'/%3e%3c/svg%3e");
    margin-bottom: 16px;

    ion-img{
        margin: auto;
        width: 313px;
        height: 221px;
        object-fit: cover;
    }

    &.no-logo{
        --inner-padding-end: 0;
        --padding-start: 0;
        
        .no-pic-wraper{
            height: 221px;
            display: flex;
            width: 100%;
            margin: auto;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2370AEFFFF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-linecap='square'/%3e%3c/svg%3e");
    
            ion-button{
                height: 53px;
                width: 53px;
                border-radius: 50%;
                border: 2px solid #FF692F;
            }
        }
    }
}
