.facebook-benefits-page {
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #16254f;

  .title {
    font-size: 1.25rem;
    line-height: 1.3;
    margin-bottom: 1.5em;
    font-weight: bold;
  }

  .list {
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    gap: 0.75rem;
    margin: 0 0 2rem;
    padding: 0;
    text-wrap: pretty;
    list-style: none;

    li {
      display: flex;
      gap: 0.5rem;

      &::before {
        content: url("../../../../../../src/images/icons/icon-checkmark.svg");
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .icons {
    display: flex;
    gap: 1em;

    .icon {
      width: 2.5em;
      height: 2.5em;
    }

    .b144 {
      color: #16254f;
    }

    .facebook {
      color: #1f5699;
    }
  }
}
