@import "../../theme/maps.scss";

.tips-container{
    text-align: center;
    --background: unset;
    width: 63px;
    height: 70px;
    padding: 0px;
    outline: unset;
    margin: 0;
    --box-shadow: none;
    p{
        font-size: 11px;
        color: var(--ion-color-primary);
    }
    svg{
        path.shape-wrapper{
            fill: var(--ion-color-primary);
        }
    }

    &.light{
        svg{
            path.shape-wrapper{
                fill: white;
            } 
        }
        p{
            color:white;

        }
    }

}

ion-popover.tips-popover{
    --background: var(--ion-color-primary)!important;
    .popover-wrapper{
        .popover-content,p{
            color:#fff;
        }
}
    cursor: pointer;

    &.light{
        --background: white!important;
        .popover-wrapper{
            .popover-content,p{
                color:black;
            }
    }

    }
    @media (max-width: map-get($grid-breakpoints, md)) {
        --width: calc(100% - (var(--ion-padding, 16px) * 2))!important;
    }


    
    ion-backdrop{
        --backdrop-opacity:0;
    }
    .popover-wrapper{
        .popover-content{
            color: white;
            text-align: right !important;
            padding: var(--ion-padding, 16px);
            border-radius: 10px !important;
            margin: -43px 0 0 -287px;
            transform-origin: right center !important;

            @media (max-width: map-get($grid-breakpoints, md)) {
                transform-origin: left center!important;
                margin: -58px -4px;
            }
        }

        .popover-arrow{
            display: none;
        }
        .tip-title-wrapper{
            display:flex;
            align-items: center;

            svg{
                margin-left:5px;
                margin-right: -7px;
            }
        }
    }

    &.popover-desktop-popover{
        .popover-content{
        transform-origin: left center!important;
        margin: -35px;
        width:350px;
        }
    }

}