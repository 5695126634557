@import "../../theme/maps.scss";

.card-wrapper {
  padding: 15px;

  @media (max-width: map-get($grid-breakpoints, sm)) {
    padding: 0;
  }
  .statistics-card {
    color: black;
    padding: 16px;

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin: 0;
      min-height: 260px;
      height: 100%;
      .statistics-action-summery-wrapper {
        max-height: 218px;
      }
    }

    .card-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;

      ion-icon {
        border-radius: 5px;
        margin-left: 10px;
        padding: 5px;
        width: 24px;
        height: 24px;
      }
    }

    .info-tag {
      display: inline-block;
      border-radius: 0.5rem;
      box-shadow: 0 0 21px 0 rgba(173, 182, 217, 0.37);
      margin: 1rem 0.5rem;
      padding: 4px 18px 4px 8px;
    }

    ion-list {
      ion-item {
        --padding-start: 0;
        --padding-end: 0;
        --min-height: 30px;
      }
      overflow: auto;
    }
  }
}

.banner {
  height: 245px;
  margin-top: 92px;
}

.pieSpan {
  border-radius: 45%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 2px;
}

[class*="itemListActions"] {
  .item {
    margin-bottom: 16px;
  }
  .item:nth-child(n + 13) {
    display: none;
  }
  &.active {
    .item:nth-child(n + 13) {
      display: block;
    }
  }

  @media screen and (max-width: 600px) {
    .item:nth-child(n + 7) {
      display: none;
    }
    &.active {
      .item:nth-child(n + 7) {
        display: block;
      }
    }
  }
}
.td-name {
  max-width: 100px;
  line-height: 44.5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-left: 5px;
}
.td-mail {
  max-width: 150px;
  line-height: 44.5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

th,
td,
td > div {
  text-align: center;
}

.padding-start-12 {
  padding-inline-start: 12px;
}
