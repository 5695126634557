@import "./maps.scss";

.input-alerts {
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  display: flex;
  padding-right: 18px;

  &.valid {
    border-top: 1px solid #4878fd;
  }

  &.error {
    border-top: 1px solid red;
  }
}
.item-invalid{
  border: 1px solid var(--ion-color-danger);
}

.input-counter {
  margin-right: auto;
}
.input-error {
  color: red !important;
  font-size: smaller;
  @media (max-width: map-get($grid-breakpoints, lg)) {
  min-height: 15px;
  }
}

ion-select,
ion-datetime {
  padding-top: 5px;
  padding-bottom: 8px;
}

.textarea-clear-button {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

ion-input button.input-clear-icon {
  position: relative;
  top: -18px;
  left: 0;
}
ion-input[type="tel"]{
  text-align: right;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

ion-textarea {
  max-height: 138px;
  overflow-x: hidden;
  --padding-top: unset !important;
  --padding-end: 10px !important;
  direction: rtl;

  @media (max-width: map-get($grid-breakpoints, md)) {
    max-height: 210px;
  }

  textarea {
    overflow: auto;
    &.native-textarea.sc-ion-textarea-md {
      overflow: hidden;
    }
  }
}
ion-item.input-item:not(.item-has-focus){
.label-floating {
overflow: visible;
}
}