@import "./maps.scss";
@import "./text.scss";
@import "./itemAndCard.scss";
@import "./popover.scss";
@import "./segment.scss";
@import "./splitPane.scss";
@import "./pagesBackgrounds.scss";
@import "./grid.scss";
@import "./textfields.scss";
@import "./toggle.scss";
@import "./buttons.scss";
@import "./toastify.scss";
@import "./header.scss";
@import "./image.scss";
@import "./checkbox.scss";
@import "./content.scss";

:root {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: var(--ion-color-primary-contrast);
  --ion-tab-bar-background: var(--ion-color-primary);
  --ion-tab-bar-color: var(--ion-color-primary-contrast);
  --ion-tab-bar-color-selected: var(--ion-color-secondary-contrast);
  --swiper-pagination-color: #16254f;
}

:root * {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 20px;
    padding: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: gray;
  }

  ion-label {
    min-height: 16px;
  }
}
