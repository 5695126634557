ion-avatar{
    width: 50px;
    height: 50px;
    position: relative;
    background: white;
  
    &.no-image{
        background: none;
        border: 1px solid rgba(252,109,69,1);
        img{
            width: 18px;
            height: 15px;
            margin: auto;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            border-radius: 0;
            left: 0;
        }
    }
  }