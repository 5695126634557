.split-pane-visible > .split-pane-side {
    border: none !important;
  }
  
  .split-pane-visible > ion-menu {
  max-width: 285px!important;
  }
  
  .split-pane-side > ion-menu{
    max-width: 200px!important; 
  }
  
  .split-pane-side{
    &::part(container){
      border-radius: 0px 0px 0px 18px;
    }
    ion-content{
      top: 0;
      background: var(--ion-color-primary);
      --border-radius: 18px 0 0 0;
      --overflow: hidden;
      @media (min-width: map-get($grid-breakpoints, lg)) {
        top: 56px;
      }
  
      &::part(scroll){
        padding: 0;
        border-radius: 18px 0 0 0;
      }
  
      ion-list{
        background: white;
        height: 100%;
        overflow: auto;
        padding-top: 23px;
        border-radius: 18px 0 0 0;
    
  

        ion-item.side-menu-desktop-header{
          margin-bottom: 20px;
        }
  
        ion-item, .menu-divider  {
          width: 230px;
          border-radius: 35px 0 0 35px;
          font-size: 14px;
          box-shadow: unset;
  
          &.active{
            --background: var(--active-menu);
            font-weight: bold;
          }
          ion-icon{
            margin-left: 11px;
          }
        }
      }
    }
  }


  .side-menu-mobile{
    z-index: 9999;
  } 