.month-filter-container{
    display: flex;
    align-items: center;
  
    @media only screen and (min-width: 992px){
      justify-content: flex-end;
    }
  }

  .monthButton {
    width: 126px;
    height: 30px;
    margin-top: 8px;
    margin-right: 16px;
    padding: 3px 6px;
    border-radius: 8px;
    box-shadow: 0 0 21px 0 rgba(173, 182, 217, 0.37);
    background-color: #ffffff;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    
    @media screen and (max-width: 600px) {
      margin-bottom: 23px;
    }
  }