@import "./maps.scss";

.comment-card {
  color: black;
  h3 {
    color: black;
  }
  ion-avatar {
    width: 35px;
    height: 35px;
    box-shadow: var(--default-shadow);
    margin-top: 5px;
  }
  .comments-text-field {
    border-radius: 0;
    --min-height: 10px;
    --padding-start: 0;
    margin-top: 0;

    ion-textarea {
      font-size: 14px !important;
    }
    &.item-has-focus {
      --background: unset;
    }
  }
  ion-button.edit-btn {
    width: 29px;
    --padding-inline-end: 0px;
    --padding-inline-start: 0px;
    --padding-end: 6px;
    --padding-start: 6px;
  }
}

.star {
  color: rgba(55, 211, 202, 1);
}

.comments-card-header {
  background: #e5fff5;
  color: black;
  .rating-wrapper {
    display: flex;
    width: 135px;
    justify-content: space-between;
  }
}

.star-icons-comments {
  color: rgba(55, 211, 202, 1);
  margin: 5px 0 5px 5px;
  vertical-align: middle;
  display: inline-block;
}

ion-segment.comments-segment,
ion-segment.leads-segment {
  width: 150px;
}

ion-segment.comments-segment
  ion-segment-button.segment-button-checked
  .segment:after {
  background: var(--review-button-background);
}

ion-segment.leads-segment
  ion-segment-button.segment-button-checked
  .segment:after {
  background: var(--business-background);
}
.comments-chip-wraper {
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-bottom: 12px;
  }
  ion-chip {
    background: var(--review-button-background);
  }
}

ion-item.item-leads {
  margin-bottom: 12px;

  ion-label {
    height: 72px;
    h3 {
      min-height: 20px;
    }
  }

  &.phone {
    ion-label {
      height: 54px;
      display: flex;
      flex-direction: column;
    }
  }
}

ion-item-sliding{
  cursor: pointer;
}