@import "../../../../theme/maps.scss";

.page-title-wraper {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;

  @media (max-width: map-get($grid-breakpoints, md)) {
    height: 90px;

    .xxl-p-mbl{
      font-size: 16px !important;
    }
  }

  &.login {
    margin-left: 0px;
    h1 {
      color: black !important;
      font-size: 21px;
      line-height: 32px;
      &:first-of-type {
        font-size: 16px;
        font-weight: normal !important;
        line-height: 24px;
        margin-bottom: 3px;
      }
    }
  }
}

ion-modal .page-title-wraper {
  height: unset;
}

.title-wrapper {
  display: flex;
  align-items: center;

  .button-pop,
  .button-flex-rows,
  .more-options-button {
    margin-right: auto;
  }

  .button-pop {
    order: 2;
  }

  .button-flex-rows {
    order: 4;
  }

  .more-options-button {
    order: 3;
    ion-icon {
      color: white !important;
    }
  }

  &.inner-page-title-wrapper {
    margin-top: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  ion-back-button {
    color: white;
    position: absolute;
    right: -70px;
    height: 45px;
    --icon-font-size: 25px;
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    &.with-tip {
      h1 {
        font-size: 5.9vw;
      }
    }
  }
}
