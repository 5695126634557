$grid-breakpoints: (
  xs: 0, 
  sm: 576px, 
  md: 768px, 
  lg: 992px, 
  xl: 1320px
);

$font-sizes: (
  xs: 12px,
  sm: 14px,
  md: 16px,
  lg: 18px,
  xl: 20px,
  xxl: 40px
)