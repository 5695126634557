.item-radio{
    box-shadow: none;
    border-radius: 0;

    --background:none;
    --background-activated:none;
--background-focused:none;
--background-hover:none;

--border-radius	: 0;

    .item-radio-btn{
        margin-left: 10px;

        --color: #3880ff;
        --color-checked: #3880ff;
    }
}
.service-chip{
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}
.item-cbx-cont{
    box-shadow: none;
        ion-checkbox{
            margin-left: 10px;
        }
}