@import "../../../theme/maps.scss";

.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 10px;
  --padding-start: 0;
  --inner-padding-end: 0;
  box-shadow: unset;

  &.no-image{
    height: 226px;
    padding: 5px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2370AEFFFF' stroke-width='3' stroke-dasharray='6%2c 10' stroke-linecap='square'/%3e%3c/svg%3e");
  
    .no-pic-wraper{
      margin: auto;

      p{
        text-align: center;
      }
    }
  }

 ion-img {
    cursor: pointer;
    width: 100%;
}

  @media (max-width: map-get($grid-breakpoints, md)) {
    margin: auto;
    width: 100%;
  }

  ion-button.upload-img-btn{
    width: 120px;
    height: 120px;
    --padding-start: 5px;
    --padding-end: 5px;
    --border-radius: 50%;
    margin:auto;

    ion-icon{
      width: 100%;
      height: 100%;
    }
  }

  ion-button.delete-btn{
    margin: 0;
  }
}

li.tie-btn-delete, li.tie-btn-deleteAll, li.tie-btn-deleteAll + li.tui-image-editor-item {
  display: none!important;
  
}

.tui-image-editor-container{
  .tui-image-editor-help-menu{
    &.top{
      max-width: 100%;
      white-space: normal !important;

      @media (max-width: map-get($grid-breakpoints, md)) {
        li{
          margin: 0;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }
}
