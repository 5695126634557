ion-grid{
    padding: 0px;
  }
  
  ion-col{
    padding-top: 0;
    padding-bottom: 0;
  
    @media (min-width: map-get($grid-breakpoints, lg)) {
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    }
  }
  
  .ion-margin-right{
    margin-right: var(--padding)
  }
  
  .ion-margin-left{
    margin-left: var(--padding)
  }

  .fixed{
    position: absolute;
    bottom: 20px;
    right: 36px;
    left: 44px;
    margin: auto;
  }

  .image-wrapper-min-height{
    min-height: calc(78vh - 160px);
  &.image-wrapper-gmb{
   min-height: calc(78vh - 245px);
    .image-preview:not(.no-image) {
      display: inline-block;
      width: auto;
      margin-bottom: 10px;
      ion-img {
        width: auto;
        height: 238px;
      }
    }
   
  }
    @media (min-width: map-get($grid-breakpoints, md)) {
      min-height: calc(350px);
      &.image-wrapper-gmb{
       min-height: calc(255px);
        .image-preview:not(.no-image) {
          display: inline-block;
          ion-img{
          width: auto;
          height: 255px;
        }
      }
      }
    }
  }
  