.openHoursCard {
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 21px 0px rgba(173, 182, 217, 0.37);

  .closeCard {
    display: flex;
    align-items: center;
    padding: 8px;

    .hours {
      margin-left: 24px;
    }

    .days {
      display: flex;
      gap: 8px;
    }

    .editBtn {
      margin-right: auto;
      border-radius: 8px;
      padding: 8px;
      transition: all;
      background-color: transparent;

      &:hover {
        background-color: #0000000d;
      }
    }
  }

  .openCard {
    display: flex;
    flex-direction: column;
    gap: 44px;
    padding: 16px 22px 34px 22px;

    .hours {
      position: relative;
      display: flex;
      gap: 16px;

      .hour {
        width: 50%;
      }
    }

    .days {
      display: flex;
      justify-content: space-between;

      .dayLabel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 9999px;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid transparent;

        &.checked {
          border: 1px solid #2897ff;
        }

        &:hover {
          border: 1px solid #2897ff;
        }

        .dayInput {
          display: none;
        }
      }
    }

    .alert {
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      padding-top: 4px;
      font-size: 12px;
      line-height: 16px;
      color: #d10b35;
    }

    .btnWrapper {
      display: flex;
      justify-content: center;
      gap: 28px;

      .saveBtn {
        border-radius: 10px;
        background-color: #16254f;
        padding: 6px 20px;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: white;
        transition: all;
        &:hover {
          background-color: #16254fe6;
        }
      }

      .deleteBtn {
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 10px;
        padding: 6px 20px;
        font-size: 16px;
        line-height: 24px;
        color: #16254f;
        transition: all;
        background-color: transparent;

        &:hover {
          background-color: #0000000d;
        }
      }
    }
  }
}

.text-darkAzure {
  color: #42a3ff;
}
