@import "../../../theme/maps.scss";

.coupon-active-wraper {
  height: 308px;
  width: 328px;
  max-width: 100%;
  cursor: pointer;
  position: relative;
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-bottom: 65px;
  }
  .coupon-description {
    background: white;
    border-radius: 20px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 169px;
    padding: 20px 26px 26px;
    z-index: 2;
    box-shadow: 0px 0px 21px 0px rgba(173, 182, 217, 0.37);

    .icon-button {
      position: absolute;
      bottom: 10px;
    }
  }

  .coupon-more-info {
    border-radius: 0 0 20px 20px;
    background: white;
    position: absolute;
    bottom: -65px;
    left: 0;
    right: 0;
    height: 80px;
    padding: 30px 26px 26px;
    z-index: 1;
    border: 1px solid var(--ion-color-primary-shade);
  }

  .small-p {
    margin-top: var(--ion-margin, 16px);
  }

  ion-img::part(image) {
    height: 308px;
    object-fit: cover;
    border-radius: 20px;
  }
}

.accordion-wrapper {
  margin: 0 auto;
  width: 100%;

  ion-item.accordion-item {
    --background-hover: none;
    --padding-start: 10px;
    --inner-padding-end: 10px;
    border-radius: 0;
    margin-bottom: 16px;

    ion-label {
      padding: 0 10px;
    }
  }

  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s;
    border-top: 0;

    p {
      opacity: 0;
      transition: 0.5s;
    }

    &.is-expanded {
      max-height: 1000px;
      overflow: hidden;
      p {
        opacity: 1;
      }
    }
  }
}

.btn-clear-icon-text {
  margin: 0;
  color: black;

  &::part(native) {
    padding-inline-start: 0;
  }
}

.coupon-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid rgb(215, 215, 215);
  box-shadow: 0 0 4px 0 rgba(195, 194, 194, 0.7);
  background-color: white;
  width: 272px;
  cursor: pointer;
  margin-bottom: 30px;

  .coupon-image {
    width: 100%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    object-fit: cover;
    height: 144px;
  }

  .coupon-body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 4px 16px;
    text-align: center;
    color: rgb(22, 37, 79);
    min-height: 200px;

    .coupon-tag-wrapper {
      position: absolute;
      right: -4px;
      top: 0;
      display: flex;
      max-width: 100%;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      border-bottom-right-radius: 0px;
      padding: 2px 10px;
      background-image: linear-gradient(to right, #ff495c, #ff692f);
      transform: translateY(-50%);

      .coupon-tag {
        position: absolute;
        bottom: 0;
        right: 0;
        border-right: 6px;
        border: transparent;
        border-top-color: rgb(255, 105, 47);
        transform: translateY(95%);
      }
      .coupon-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
      }
    }

    .b144-exclusive {
      display: flex;
      align-items: center;
      gap: 2px;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 6px;

      .b144-exclusive-icon {
        height: 16px;
        width: 16px;

        @media (min-width: 768px) {
          height: 16px;
          width: 16px;
        }
        @media (min-width: 414px) {
          height: 26px;
          width: 26px;
        }
        @media (min-width: 375px) {
          height: 22px;
          width: 22px;
        }
      }
    }

    .coupon-description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-size: 15px;
      line-height: 18px;
      font-weight: bold;
      color: rgb(22, 37, 79);
      margin-bottom: 8px;
    }

    .discount-prices {
      color: #2997ff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      margin-bottom: 8px;

      .before-discount {
        font-size: 13px;
        color: #16254f;
        text-decoration-line: line-through;
      }

      .after-discount {
        font-size: 13px;
      }
    }

    .coupon-expiration {
      height: 21px;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .coupon-button {
      background-color: rgb(22, 37, 79);
      margin-bottom: 6px;
      margin-top: auto;
      height: 32px;
      width: 80px;
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      padding: 0;
      font-size: 15px;
      line-height: 16px;
      box-shadow: none;
      border-radius: 50%;
      pointer-events: none;
    }

    .coupon-AdditionalInfo {
      font-size: 12px;
      line-height: 1.75;
      min-height: 21px;
    }

    .coupon-more-info {
      border-radius: 0 0 20px 20px;
      background: white;
      position: absolute;
      bottom: -65px;
      left: 0;
      right: 0;
      height: 80px;
      padding: 30px 26px 26px;
      z-index: -1;
      border: 1px solid var(--ion-color-primary-shade);
    }
  }
}

.date-range-picker {
  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-bottom: 25px;
  }
}
