ion-item,
ion-card {
  border-radius: 10px;
  box-shadow: 0px 0px 21px 0px rgba(173, 182, 217, 0.37);
  &.input-item,
  &.item-select,
  &.item-datetime {
    border-radius: 10px 10px 0 0;
    box-shadow: none;
    .input-clear-icon,
    ion-select::part(icon) {
      top: -7px;
    }
    .input-clear-icon {
      display: none;
      width: 7px;
      height: 7px;
    }

    &::part(native) {
      min-height: 56px;
      height: 56px;
      border-color: #6d7278;
      border-width: 0px 0px 1px;
    }
    &.item-has-focus {
      &::part(native) {
        border-color: #4878fd;
      }
      &.item-has-value .input-clear-icon {
        display: block;
        left: 10px;
      }
    }

    &.item-has-value::part(native) {
      border-color: #4878fd;
    }
    &.item-invalid::part(native) {
      border-color: red;
    }
  }

  &.item-has-value,
  &.item-has-focus,
  &.item-datetime {
    ion-label {
      font-size: 12px !important;
      line-height: 20px;
    }
    &.item-textarea {
      --highlight-background: none;
      --border-width: 0;
    }
  }

  &.item-interactive-disabled {
    opacity: 0.8;
    ion-input,
    ion-label,
    .native-input[disabled].sc-ion-input-md {
      opacity: unset !important;
    }
  }
}

ion-item.accordion-item,
ion-item.toggle-item,
ion-item.checkbox-item,
ion-item.in-list,
ion-item.item-datetime,
ion-item.item-select {
  --background-color: unset;
  background-color: unset;
  box-shadow: unset;
}

ion-card {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  padding: 16px;
  margin: 0 0 16px;
  ion-card-header {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    padding: 0;
  }
  ion-card-content,
  .card-content-ios,
  .card-content-md {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    padding: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
  ion-col {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  ion-item {
    padding-left: unset;
    padding-right: unset;
    --padding-inline-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
  }
}

ion-card,
ion-toolbar {
  ion-item.item {
    box-shadow: none;
  }
}

.item-preview {
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  background: black;
  ion-img::part(image) {
    border-radius: 0;
    max-width: 100%;
    max-height: 100%;
    height: initial;
    width: initial;
    margin: auto;
  }
  ion-badge {
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding: 10px 20px;
    border-radius: 50px;
  }
}

.item-highlight,
.item-inner-highlight {
  left: 0px;
  right: 0px;
  bottom: -1px;
  position: absolute;
  background: #4878fd;
  z-index: 1;
}

ion-item.item-has-focus {
  --background: #eff3ff;
}

.item.item-trns {
  border-color: rgba(0, 0, 0, 0);
  --background: rgba(0, 0, 0, 0);
  color: white;
}

.checkbox-list {
  ion-item {
    box-shadow: unset;
    --background-hover-opacity: 0;
    --ripple-color: white;
    --inner-padding-top: 1px;

    ion-label {
      margin: 0;
    }

    ion-checkbox {
      margin: 0px 0px 0px 8px;
      --checkmark-color: #4878fd;
      --background: #eff3ff;
      --background-checked: #eff3ff;
      --border-radius: 5px;
      --border-color: #4878fd;
      --size: 24px;
      --border-width: 1px;
      --checkmark-width: 1;

      &::part(container) {
        padding: 3px;
      }
    }
  }
}

.dv-info-card{
  position: relative;
  margin-top: 16px;
  
  img{
    position: absolute;
    z-index: 1; 
    top: 3px;
  }
  ion-card{
    padding-top:4px;
    padding-bottom: 4px;
    margin-right:9px;
    margin-bottom:9px; 
    display: inline-block;
    line-height:18px; 
    color: var(--ion-color-primary);

    ion-button{
      text-decoration: underline;
      margin:0;
      height:18px;
      vertical-align: bottom;
      --padding-start:6px;
      --padding-end:0;
    }
  }
}