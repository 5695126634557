
@import "../../theme/maps.scss";

.login{
    .title-wrapper{
        justify-content: center;
        @media (max-width: map-get($grid-breakpoints, md)) { 
            justify-content: flex-start;
        }
    }
    ion-item.input-item:not(.item-has-focus) .label-floating{
        z-index: 5;
    }
    .native-input.sc-ion-input-md{
        padding-top: 0;
        &:-webkit-autofill {
            background-color: transparent !important;
            -webkit-box-shadow: 0px 0px 0px 20px var(--ion-color-light) inset;
        }
    }
    // .native-input.sc-ion-input-md:-webkit-autofill {
    //     background-color: transparent !important;
    //     -webkit-box-shadow: 0px 0px 0px 20px var(--ion-color-light) inset;
    // }
}


.actions-wrapper{
    position: fixed;
    width: 93%;
    max-width: 450px;
    right: 0;
    left: 0;
    text-align: center;
    margin: auto;

    @media (max-width: map-get($grid-breakpoints, md)) { 
        // bottom: 0;
    }


    ion-button.main-button{
        margin-top: 32px;
        margin-bottom: 16px;

    }

    ion-button.login-switch-btn{
        color: var(--links);
        text-decoration: underline;
        font-weight: bold;
        font-size: 16px;
    }
}

.login-wrapper{
    max-width: 450px;
}
