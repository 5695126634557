@import "../../../theme/maps.scss";

.ad-badge {
    font-weight: bold;
    color: #000;

    &:after {
        content: '.';
        position: relative;
        top: -2px;
        left: 2px;
    }
}

.preview-content-wrapper {
    border: 4px solid #f1f3f4;
    margin: 0 8px;
    background: #f1f3f4;
    width: 75.5%;
}

.preview-mobile {
    flex-wrap: wrap;
    justify-content: center;

    .preview-content-wrapper {
        width: 100%;
    }

    .preview-content {
        border-radius: 8px;
    }
}

.preview-content {
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    font-size: 14px;
    line-height: 22px;
    padding: 10px;
    position: relative;
}

.preview-content a:hover {
    text-decoration: none;
}

.url-section {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

.headline-section {
    color: #1a0dab;
    font-size: 14px;
    margin: 3px 0;
    font-weight: bold;
}

.preview-mobile .headline-section {
    margin: 12px 0;
    color: #1558d6;
    display: -webkit-box;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.preview-mobile .pathurl {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    white-space: nowrap;
}


.description-section {
    font-size: 12px;
    line-height: 1.4;
}

.triangle {
    border-color: #70757a transparent;
    border-style: solid;
    border-width: 3px 3px 0;
    margin-left: 3px;
    display: inline-block;
}

.preview-mobile .triangle,
.info-icon {
    display: none;
}

.ad-field-label {
    min-width: 70px;
}

.ad-field-input,
.ad-field-text,
.ad-field-input-inline {
    min-height: 30px;
    border: 1px solid #CED8E3;
    border-radius: 3px;
    padding: 0px 10px;
    width: calc(100% - 75px);
}

.ads-fields {
    padding: 0 20px 0 25px;
}

.ad-field-label-inline {
    margin: 0 10px 0 5px;
}

.ad-field-input-inline {
    width: calc(100% - 75px);
}

.google-keywords {
    display: flex;
    flex-wrap: wrap;
    margin: 12px 0 50px;

    .google-keyword {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.2;
        color: #16254f;
        cursor: default;

        span {
            display: inline-block;
        }
    }
}

// .reseller-notes {
//     .label-floating.sc-ion-label-md-h {
//         transform: translateY(20%);
//         color: #666666;
//     }

//     .input-item,
//     .item-input {
//         width: 73%;
//         --ion-color-base: white !important;
//         --padding-start: 6px !important;
//     }

//     // .input-item.input-item-bordered {
//     //     &::part(native) {
//     //         height: 76px;
//     //       }
//     // }
//     .input-alerts {
//         width: 73%;
//         padding-right: 0;

//         &.valid {
//             border: none;
//         }
//     }
// }

.discount-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    ion-item.item-radio {
        margin-left: 50px;

        &:last-of-type {
            margin-left: 8px;
        }
    }

    ion-input.customDiscount {
        margin-right: 6px;
        width: 53px;
        height: 36px;
        border-radius: 5px;
        border: solid 1px #16254f;
        background-color: #fff;

        &::part(native) {
            height: 30px;
            min-height: 30px;
        }
    }

}

.ads-preview {
    margin: 30px 0 12px;

    .large-p {
        margin: 0 10px 10px;
    }
}

.action-call-consultant-card {
    .custom-radio-group {
        flex-wrap: wrap;
    }

    .item-radio {
        .item-radio-label {
            white-space: nowrap;
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7) {
            width: 28%;
        }

        &:nth-child(2),
        &:nth-child(5) {
            width: 21%;
        }

        &:nth-child(3),
        &:nth-child(6) {
            width: 41%;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
            width: 100% !important;
        }
    }
}

.ads-additional-card {
    .custom-radio-group {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    .item-radio {
        min-width: 125px;

        .item-radio-label {
            white-space: nowrap;
        }

        width: 28%;

        &:nth-child(2),
        &:nth-child(5) {
            width: 22%;
        }

        &:nth-child(3) {
            width: 35%;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
            width: 100% !important;
        }
    }

    .input-item.input-item-bordered {
        &::part(native) {
            height: 36px;
            min-height: 36px;
        }
    }
}

.ads-additional-card.mobile-view .item-radio,
.action-call-consultant-card.mobile-view .item-radio {
    width: 100%;
}

.self-sale-card.ads-notes-card .self-sale-section-subtitle {
    margin-bottom: 22px !important;
}

.reseller-notes, .ads-notes-card,
.ads-additional-card {
    .custom-text-input {
        width: 78%;
    }

    &.mobile-view {

        .custom-text-input,
        .input-alerts {
            width: 100%;
        }
    }

    .custom-text-input.small {
        ion-item::part(native) {
            height: 36px;
            min-height: 36px;
        }
    }
}

.self-sale-card.mobile-view .discount-group {
    display: block;
}

.self-sale-card-mobile {
    &.ads-additional-card,
    &.ads-notes-card {
        .custom-text-input {
            width: 100%;
        }
    }
}