ion-header {
  position: relative;
  z-index: 1;

  ion-toolbar{
    &.ios{
      padding-top:unset;
    }
  }
}

ion-toolbar{
    @media (max-width: map-get($grid-breakpoints, md)) {
    height: 56px;
    }
  
    &.hamburger-toolbar{
      height: 92px;
      padding: 15px 0px!important;
  
      ion-icon{
        font-size: 24px;
        position: relative;
        top: -18px;
        left: 6px;
      }
    }
    .toolbar-title{
      color: var(--ion-color-primary);
      font-size: 16px;
      @media (max-width: map-get($grid-breakpoints, md)) {
        font-size: 14px;
        }
    }
  }
  