@import "../../../../theme/maps.scss";

.date-reange-modal .modal-wrapper {
  ion-button.main-button {
    margin-top: 0;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    height: 680px;
    width: 720px;

    .ion-page {
      padding: 0px 20px;
    }
  }
}

.rdrDateRangePickerWrapper {
  flex-direction: column;
  direction: ltr;
  width: 100%;
  margin-top: 20px;
}

.rdrDefinedRangesWrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #b9b6b6;
  border-right: 0 !important;

  @media (min-width: map-get($grid-breakpoints, md)) {
    order: 2;
    align-items: center;
    padding: 6px 0;
  }
}

.rdrStaticRangeLabel {
  @media (max-width: map-get($grid-breakpoints, md)) {
    padding: 10px 5px;
  }
}

.rdrStaticRanges {
  justify-content: space-between;
  width: 100%;
}

.rdrMonth {
  width: 100%;
}

.rdrMonths,
.rdrMonthAndYearWrapper,
.rdrMonthAndYearPickers,
.rdrDateDisplay,
.rdrStaticRanges {
  flex-direction: row-reverse;
}

.rdrInputRanges {
  display: flex;
}

.rdrMonthAndYearWrapper {
  max-width: 320px;
  margin: auto;
  padding-top: 0 !important ;

  .rdrNextPrevButton {
    transform: rotate(180deg);
  }
}

.rdrDateDisplayWrapper {
  border-bottom: 1px solid #b9b6b6;
}

.rdrMonths.rdrMonthsHorizontal {
  border-top: 1px solid #b9b6b6;
}

.rdrMonthName {
  text-align: center;
}

.date-range-button {
  border-bottom: 1px solid;
  --padding-end: 0;
  --padding-start: 8px;
  margin-bottom: 20px;

  @media (max-width: map-get($grid-breakpoints, md)) {
    margin-top: 20px;
  }
}
