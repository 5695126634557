@import url('https://fonts.googleapis.com/earlyaccess/opensanshebrew.css');

:root {
  --ion-color-primary: #16254f;
  --ion-color-primary-rgb: 109, 151, 223;
  --ion-color-primary-contrast: #ffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #6085c4;
  --ion-color-primary-tint: #7ca1e2;

  --ion-color-secondary: #cf22e8;
  --ion-color-secondary-rgb: 207, 34, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #b61ecc;
  --ion-color-secondary-tint: #d438ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light:#F2F7FC;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --toggle-checked: #4878FD;
  --links: #4878FD;
  --emph-blue:#4878FD;
  --popover-button:#4878FD;

  --toggle-checked-background:#E7EDFF;
  --ion-grid-width-xl: 1011px; 
  --ion-font-family: 'Open Sans Hebrew';
  --border-raduis: 30px;
  --button-gradient: linear-gradient(90deg, rgba(255,69,110,1) 0%, rgba(252,109,69,1) 100%);
  --statistic-button-background: linear-gradient(315deg, rgba(255,182,67,1) 0%, rgba(255,105,47,1) 100%);
  --statistic-header-background: linear-gradient(315deg, rgb(255 234 201) 0%, rgb(255 232 223) 100%);
  --business-background: linear-gradient(142deg, rgba(66,163,255,1) 0%, rgba(144,235,255,1) 100%);
  --review-button-background: linear-gradient(142deg, rgba(55,211,202,1) 0%, rgba(153,233,161,1) 100%);
  --review-header-background: linear-gradient(142deg, rgb(225 241 240) 0%, rgb(224 249 227) 100%);
  --inbox-button-background: linear-gradient(142deg, #FA016D 0%,#DB41CF 100%);
  --inbox-header-background: linear-gradient(142deg, #ffdeec 0%,#e8dce7 100%);
  --settings-button-background: linear-gradient(142deg,#596164 0%,#868F96 100%);
  --help-button-background: linear-gradient(142deg, #0BA360 0%, #3CBA92 100%);
  --business: linear-gradient(142deg, rgba(66,163,255,1) 0%, rgba(144,235,255,1) 100%);
  --active-menu: linear-gradient(90deg, rgba(255,69,110,0.1) 0%, rgba(252,109,69,0.1) 100%);
  --padding: 21px;
  --default-shadow: rgba(163,180,231,0.47) 0px 2px 4px;


}

