.google-intro-page {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .google-icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1em;
  }

  .title {
    font-size: 1.75rem;
    line-height: 1.43;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .paragraph {
    font-size: 1.125rem;
    line-height: 1.44;
    margin-bottom: 2em;
    padding-inline: 1em;
  }

  .google-partner {
    width: 3.875rem;
    height: auto;
  }
}
