@import "../../theme/maps.scss";

:root {
  --bullet-background: val(--ion-color-primary-contrast);
  --bullet-background-active: val(--business);
}
ion-slides {
  height: 100%;
}

ion-card.slide-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px !important;
  width: 500px;
  max-width: 100%;
}

.custom-select-alert{
  .alert-wrapper{
    width: 320px;
    max-width: 90%;

    .alert-radio-label{
      white-space: normal;
    }
  }
}

.main-form-cont {
  height: 100%;
  justify-content: center;
  @media (min-width: map-get($grid-breakpoints, md)) {
  .col-1{
    padding-left: 19px;
    }
  .col-2{
    padding-right: 19px;
    }
  }
  @media (max-width: map-get($grid-breakpoints, lg)) {
  ion-col {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    }
  }

  .item-cbx{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #747575;
  }
  ion-select{
    padding-top:8px;
  }
  ion-item.item-select::part(native){
    min-height: 61px;
  }
}
