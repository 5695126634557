.trends {
  max-width: 270px;
  width: 100%;
  margin: 0;

  .trends-icon {
    font-size: 24px;
  }

  .trends-headers-col {
    max-width: 170px;
    width: 100%;

    .trends-header {
      color: black;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.37;
    }

    .trends-subheader {
      font-size: 0.75rem !important;
      line-height: 1.67;
    }
  }

  .trends-value-col {
    margin-inline-start: auto;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;

    .trends-value {
      text-align: left;
      font-size: 30px;
      color: #383b41;
      line-height: 24px;
      margin-top: 20px;
    }
    .percentage {
      display: flex;
      align-items: center;
      font-size: 0.625rem;
      margin-bottom: -20px;

      .icon {
        width: 0.75rem;
        height: 0.75rem;
        color: #4878fd;
      }
    }
  }
}
