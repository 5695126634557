@import "../../../../../theme/variables.scss";

.statistics-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--ion-color-primary);

    .icon {
      width: 36px;
      height: 36px;
      margin-bottom: 8px;
    }

    .upper-text {
      font-size: 44px;
      line-height: 40px;
      margin-bottom: 4px;

      span {
        font-size: 22px;
      }
    }

    .lower-text {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .separator {
    height: 1px;
    width: 74px;
    background-color: #979797;
    margin-block: 32px;
  }
}
