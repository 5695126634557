.backdrop {
  position: fixed;
  inset: 0;
  // z-index: 30;
}

.highlighter {
  position: relative;
  box-shadow: 0 0 0 99999px rgba(150, 150, 150, 0.8);
  transition: all 0.3s ease, border 0.1s ease;
  text-wrap: balance;
  font-size: 4.26666vw;
  transition: all 0.3s;
  scroll-margin: 16px;
  // z-index: 40;

  &::before {
    transition: all 0.1s;
    content: "";
    position: absolute;
    inset: -4px;
    background: linear-gradient(to right, #42a3ff, #90ebff);
    filter: blur(0px);
    pointer-events: none;
    transform: translate3d(0px, 0px, -1px);
    clip-path: polygon(
      -100vmax -100vmax,
      100vmax -100vmax,
      100vmax 100vmax,
      -100vmax 100vmax,
      -100vmax -100vmax,
      4px 4px,
      4px calc(100% - 4px),
      calc(100% - 4px) calc(100% - 4px),
      calc(100% - 4px) 4px,
      4px 4px
    );
  }

  .popover {
    transition: all 0.3s ease;
    transform-origin: bottom right;
    position: absolute;
    width: 19em;
    bottom: 0;
    right: 50%;
    transform: translate(50%, calc(100% + 1.25em));
    background-color: white;
    box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.5);
    border-radius: 0.5em;
    padding: 0.5em 0.5em 1.5em;
    color: #16254f;
    text-align: center;
  }

  .triangle {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, -75%);
    border-style: solid;
    border-width: 0 0.625em 0.75em 0.625em;
    border-color: transparent transparent #ffffff transparent;
  }

  .close {
    font-size: 4.26666vw;
    margin: 0.125em auto 0 0.125em;
    padding: 0;
    background: none;
    display: block;
    width: 1em;
    height: 1em;
    .x {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-size: 1.0625em;
    font-weight: bold;
    line-height: 1.0625em;
    margin-bottom: 0.625em;
    letter-spacing: 0.026875em;
  }

  .text {
    line-height: 1.375;
    letter-spacing: 0.025;
    font-size: 1em;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  &[data-visible="false"] {
    box-shadow: none;
    border-width: 0;

    &::before {
      inset: 0;
      opacity: 0;
    }

    .popover {
      scale: 0;
    }
  }
}
