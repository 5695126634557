@import "../../theme/maps.scss";

.section-item-small{
  --min-height: 357px;

  ion-button.main-button{
    position: absolute;
    bottom: 10px;
    left: 15px;
    right: 0;
  }
}
.section-header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.section-list {
  li {
    padding: 8px 0px;
  }
  &.check-icon {
    list-style: none;
    li {
      position: relative;
    }
    li::before {
      content: '';
      position: absolute;
      right: -22px;
      top: 12px;
      height: 5px;
      width: 10px;
      border-bottom: 3px solid #383b41;
      border-left: 3px solid #383b41;
      transform: rotate(-45deg);
    }
  }
}
.section-item{   
    ion-label{
      padding: 24px 0;
      margin: 0;
      flex-direction: column;
      display: flex!important;
      height: 100%;
      
      ion-img{
        margin: 9px 0;
        &::part(image) {
          width: 65%;
          margin: auto;
        }
      }
      img{
        width: fit-content;
      }
    }

    .main-button{
      margin-top: auto;
    }
    .button-small {
      font-size: 14px;
      height: 47px;
    }
}


.section-item-full{
  --min-height: 589px;
}

.section-item-center{
  ion-label{
    text-align: center;

    img{
      align-self: center;
    }
  }
}


.extra-margin{
  margin-top: 34px;
}
.self-reg-cont{
  ion-label {
    justify-content: space-between;
    white-space: initial !important;
  }
  .google-logo {
    padding: 2px 0px 15px;
  }
  .b144-logo {
    height: 40px;
    padding-bottom: 8px;
  }
  .section-header {
    flex-direction: column;
    padding-bottom: 25px;
  }
  @media (max-width: map-get($grid-breakpoints, sm)) {
    > ion-col{
      margin-bottom: 24px;
      padding-left: 0;
        padding-right: 0;
      &:first-child{
        margin-top: 30px;
      }

      .main-button{
        width: 228px;
        margin: auto;
      }
    }
  .section-header{
    padding-inline: 0px;
    margin-inline: 0px;
  }}
  ion-item{
    height: 100%;
    font-size: 14px;
    &::part(native) {
      height: 100%;
    }
}

}

