.assetGroupPreviewModal {
  * {
    box-sizing: unset !important;
  }

  .modal-body {
    background: rgba(33, 33, 33, 0.1);
    height: 85vh;
    direction: ltr;
    user-select: none;
    overflow: hidden;

    @media screen and (max-width: 375px), screen and (max-height: 700px) {
      height: 110vh;
    }
  }
}

.opac {
  opacity: 0.5;
}

.nav-buttons-prod svg {
  height: 26px;

  @media (min-width: 768px) {
    width: 26px;
  }
}

.mobile-view {
  opacity: 0.2;
}

.desktop-view {
  opacity: 0.2;
}

.link-btn {
  background: unset;
}
.activePreview {
  border-bottom: 1px solid;
}
