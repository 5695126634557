@import "../../../theme/maps.scss";

ion-slide{
    .slide-container{
        height: 90vh;
        width: 100%;
        ion-img {
          height: 100%;
          object-fit:contain;
          margin: 0 20px;
        } 
        .image-preview-badge {
          position: absolute;
          bottom: 40px;
          right: 0;
          min-width: 102px;
          border-radius: 15px;
  
          @media (max-width: map-get($grid-breakpoints, md)) {
            bottom: 60px;
          }
        }
    }
    
  }