.page-icon {
  &.icon-background-orange,
  &.statistics,
  &.statistics-main {
    background: var(--statistic-button-background);
  }
  &.icon-background-blue,
  &.bussiness {
    background: var(--business-background);
  }

  &.review {
    background: var(--review-button-background);
  }

  &.inbox {
    background: var(--inbox-button-background);
  }

  &.settings {
    background: var(--settings-button-background);
  }

  &.help {
    background: var(--help-button-background);
  }
  &.icon-background-white,
  &.statistics-asset {
    background: white;
    box-shadow: 0px 0px 5px 0px black;
  }
}

.custom-list-button-wraper:not(.inner) {
  &:hover {
    &:before {
      content: "";
      position: absolute;
      top: 11px;
      right: 13px;
      left: 11px;
      z-index: 1;
      margin-right: 18px;
      border-radius: 10px;
      height: 84px;
      display: block;
    }
  }
  @media (max-width: map-get($grid-breakpoints, md)) {
    &:hover {
      &:before {
        content: "";
        position: absolute;
        top: -1px;
        right: 32px;
        left: 4px;
        z-index: 1;
        margin-right: -16px;
        border-radius: 10px;
        height: 84px;
        display: block;
      }
    }
  }

  &.icon-background-orange:before,
  &.statistics:hover:before,
  &.statistics-main:hover:before {
    background: var(--statistic-button-background);
  }
  
  &.icon-background-blue:before,
  &.bussiness:hover:before {
    background: var(--business-background);
  }

  &.review:hover:before {
    background: var(--review-button-background);
  }

  &.inbox:hover:before {
    background: var(--inbox-button-background);
  }

  &.settings:hover:before {
    background: var(--settings-button-background);
  }

  &.help:hover:before {
    border-color: var(--help-button-background);
  }
}
.custom-list-button-wraper {
  &.inner {
    ion-item.statistics,
    ion-item.statistics-main {
      --background: var(--statistic-header-background);
    }

    ion-item.review {
      --background: var(--review-header-background);
    }

    ion-item.inbox {
      --background: var(--inbox-header-background);
    }
    ion-item.help {
      --background: var(--help-button-background);
    }
  }
}

.custom-list-button-wraper.no-hover:hover:before {
  display: none;
}
