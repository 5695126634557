@import "../../theme/maps.scss";
@import "../../theme/variables.scss";

.co-sale-header {
  height: 48px;
  width: 100%;
  background: var(--ion-color-primary);
  position: fixed;
  top: 0;
  color: #fff;
  text-align: center;
  line-height: 48px;
  font-size: 16px;
  font-weight: bold;
}

.co-sale-footer {
  background-attachment: fixed;
  height: 40px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  box-shadow: 2px -1px 4px 0 rgba(110, 110, 110, 0.5);

  ion-button {
    --padding-start: 0;
    --padding-end: 0;
    --ripple-color: #fff;
  }
}

.co-sale-page {
  ion-toolbar {
    display: none;
  }
  .co-sale-content {
    --background: #f5f5f5;
  }
  ion-content {
    --padding-top: 0px !important;
    --padding-bottom: 0px;
    --padding-start: 0 !important;
    --padding-end: 0 !important;
    --background: #f5f5f5 !important;
  }
  .ion-page > ion-content {
    --padding-top: 48px !important;
    --padding-bottom: 40px;
    --padding-start: 0 !important;
    --padding-end: 0 !important;
  }
}

.co-sale-menu {
  top: 0;
  right: 0;
  
  ion-fab-button {
    --box-shadow: none;
    height: 48px;
    --border-radius: 0;
    --ripple-color: transparent;
    --background: transparent;
  color: #fff;
  }
  .co-sale-menu-btn{
    background-color: transparent;

  }
  ion-fab-list {
    margin-top: 48px;
    ion-list {
      width: 246px;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: 0 0 14px 0 rgba(159, 168, 202, 0.37);

      ion-item {
        --padding-start: 0;
        --inner-padding-start: 10px;
        border-radius: 0;
        --min-height: 56px;

        ion-button {
          font-size: 14px;
          ion-icon {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.co-sale-start {
  padding: 0 10px;
  h2 {
    font-size: 20px;
    text-align: center;
  }
  p{
    font-size: 16px;
    
  }

  ion-button {
    text-align: right;
    --background: #fff;
    --color: var(--ion-color-primary);
    width: 100%;
    height: 69px;
    margin-left: 0;
    margin-top: 8px;
    margin-right: 0;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 18px;
    justify-content: start;

    .co-sale-main-btn {
      width: 100%;
      text-indent: 50px;
      vertical-align: 34px;
      .co-sale-main-btn-text {
        padding-top: 5px;
      }
      ion-icon {
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }
}

.cosale-wrapper {
  max-width: 720px;
}
