ion-content {
  --background: #16254f1a;
  --padding-top: 50px;
  @media (max-width: map-get($grid-breakpoints, md)) {
    --padding-top: 56px;
    --padding-start: 16px;
    --padding-end: 16px;
  }

  &.inner-page{
    @media (max-width: map-get($grid-breakpoints, md)) {
      --padding-start: 16px;
      --padding-end: 16px;
    }
  }
  &.login{
    --background:white;
  }
}
  
.Table {
  display : flex;
  flex-direction : column;
  line-height: 1.5;
  justify-content : space-between;
}
  
.Table-row {
  display: flex;
  flex-flow: row nowrap;
}
  
.Table-row-item {
  display : flex;
  flex-grow :1;
  padding: 5px 0.5em;
  word-break: break-word;
  display: inline-block;
}
  
.u-Flex-grow3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}

.main-content{
  position: relative;

  .action-button-wrapper {
    position: absolute;
    top: -120px;
    left: 0;
  }
}

.blank-list-icon{
  width: 112px;
  height: 112px;
} 

.wrapper{
  background-color: var(--ion-color-primary);
  width: 100%;
  height: 122px;
  display: block!important;
  position: absolute;
  top: 56px; 
  right: 0;
  border-radius: 0 0 20px 20px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    border-radius: 0 0 0px 20px;
    height: 194px;
  }
}


.error_page {
  text-align: center;
  direction: rtl;
  padding-top: 270px;
  font-size: 14px;
  line-height: 18px;
  color: #16254f;

  @media screen and (max-width:480px) {
        padding-top: 160px;
        img {
          width: 112px;
        }
    }
.error_msg_long {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 30px;
  @media screen and (max-width:480px) {
    font-size: 16px;
    line-height: 26px;
  }
}     
}

ion-modal.modal-color-picker{
  --width:377px;
  --max-height: 527px;
  
  @media (max-width: map-get($grid-breakpoints, md)) {
    --width:328px;
    --max-width: 100%;
    --border-radius:16px;
    --max-height: 510px;
  }
  ion-backdrop{
    opacity: 0 !important;
  }
  .modal-wrapper{
    box-shadow: 0 0 21px 0 rgba(47, 55, 83, 0.37);
    ion-header{
      padding-top:8px;
    }
  ion-content{
    --padding-start:24px;
    --padding-end:24px;
    --padding-bottom: 0;
    --offset-bottom: 0 !important;
    @media (max-width: map-get($grid-breakpoints, md)) {
      --padding-start:14px;
      --padding-end:14px;
    }
  }
}
}

.dv_color_picker_cont{
  .dv_color_blocks{
    width: calc(42px * 8);
    padding:10px 0 20px;
    margin-bottom: 17px;
    border-bottom: 2px solid rgba(47, 55, 83, 0.37);
    direction: ltr;
    @media (max-width: map-get($grid-breakpoints, md)) {
      width: calc(38px * 8);
    }
    .btn-color-block{
      width: 36px;
      height: 36px;
      border-radius: 2px;
      margin: 3px;
      box-shadow: none;
      --box-shadow:(none);
      font-size: 0;
      @media (max-width: map-get($grid-breakpoints, md)) {
        width: 32px;
      height: 32px;
      }
      &.selected{
        border: 2px solid #000;
        box-shadow: inset 0 0 0 3px #fff;
      }
    }
  }
  .dv_color_picker{
    .dv_color_selector{
      display: inline-block;
      width: calc(100% - 150px);
      vertical-align: top;
      @media (max-width: map-get($grid-breakpoints, md)) {
        width: calc(100% - 142px);
      }
      .sketch-picker {
        border: 0 !important;
        box-shadow: none !important;
        width: 100% !important;
        padding: 0 !important;
        &> div{
          &:nth-child(1){
            border-radius: 2px;
            border: 1px solid var(--ion-color-primary);
          }
          &:nth-child(2){
            >:nth-child(1){
              >div{
                height: 30px !important;
                border-radius: 2px;
                border: 1px solid var(--ion-color-primary);
                .hue-horizontal > div > div{
                  height: 28px !important;
                }
              }
            }
            >:nth-child(2){
              display: none !important;
            }
            }
          &:nth-child(3){
          display: none !important;
          }
        }
      }
    }
    .dv_color_panel{
      display: inline-block;
      width: 150px;
      padding-right: 13px;
      @media (max-width: map-get($grid-breakpoints, md)) {
        padding-right: 12px;
        width: 142px;
      }
      .dv_color_preview{
        width: 56px;
        height: 56px;
        border-radius: 9px;
        border: 1px solid var(--ion-color-primary);
        margin: 33px auto 40px;
        @media (max-width: map-get($grid-breakpoints, md)) {
          margin: 28px auto;
        }
      }
      .dv_color_input{
        input{
          width: 136px;
          height: 40px;
          direction: ltr;
          text-transform: uppercase;
          padding: 0;
          line-height: 40px;
          text-align: center;
          border-radius: 7px;
          border: solid 2px #ddd;
          background-color: #f9f9f9;
          font-size: 16px;
          color: var(--ion-color-primary);
          @media (max-width: map-get($grid-breakpoints, md)) {
            width: 130px;
          }
        }
      }
    }
  }
}
.color-main-button{
  height: 40px !important;
  margin-top: 24px !important;
  margin-bottom: 3px !important;
}


.b144-loader-cont{
height: 100vh;
z-index: 99999;
    position: relative;
& > div{
  height: 100vh;

  @media (min-width: 500px) {
    padding-left: calc(50% - 250px);
    padding-right: calc(50% - 250px);
    svg{
      max-width: 500px;
    }
  }
}
}

.select-extra-long{
  
  .alert-wrapper{
    width: 380px;
    max-width: 380px;
    @media (max-width: 380px) {
      max-width: 95%;
    }
    button.alert-radio-button{
     height: 54px;
     border-bottom: 1px solid #e7e9ed;
    .alert-radio-label{
      padding-inline-start: 34px;
      overflow: visible;
      white-space: normal;
    }
  }
  }
}