.pic-checkbox{
    right: 15px;
    top: 8px;
    position: absolute;
    --border-color: white;
  }
  
  ion-checkbox {
    --background: none;
    --border-width: 2px;
    --border-color-checked:#4878FD;
    --checkmark-color: #4878FD;
    --background-checked: white;
  }

  .multi-choice-wraper {
    display: flex;
    align-items: center;
    margin: 10px 0;
  
    ion-checkbox {
      margin-left: 10px;
    }
  }