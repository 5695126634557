.input-hidden {
    opacity: 0;
    width: 0;
    height: 0;
    line-height: 0;
    max-height: 0;
    overflow: hidden;
  }
  .auto-complete-cont {
    position: absolute;
    z-index: 10;
    width: calc(100% - 10px);
    height: auto;
    overflow: hidden;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 0;
    margin-right: 5px;
  
    .auto-complete-item {
      border-radius: 0;
      text-indent: 15px;
      &:hover {
        font-weight: bold;
      }
    }
  }
  