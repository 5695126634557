ion-segment-button{
    height: 35px;
    color:black;
    
  
    @media (max-width: map-get($grid-breakpoints, md)) {
      font-size: 14px;
    }

    &.segment-button-checked{
        font-weight: bold;
    }
   
  }

  
  ion-segment-button.segment-button-checked::part(indicator) {
    display: none;
  }

  ion-segment{
    overflow-x: hidden;
    @media (max-width: map-get($grid-breakpoints, md)) {
      flex-wrap: wrap;
    }

  }
  .seg-bus-card{
    ion-segment-button{
      max-width: 75px;
      &.segment-button-md{
        max-width: 90px;
    }
      &.segment-button-lg{
        max-width: 110px;
    }
    }
  }
  .seg-prod-stat{
    ion-segment-button{
      max-width: 230px;
    }
  }