@import "../../../../theme/variables.scss";

.b144-btn {
  border-radius: 999px;
  font-weight: bold;

  &--primary {
    background-color: var(--ion-color-primary);
    color: white;
  }

  &--secondary {
    background-color: white;
    color: var(--ion-color-primary);
  }

  &--gray {
    background-color: white;
    color: #666666;
    border: 2px solid #9b9b9b;
  }

  &--sunset {
    background-image: linear-gradient(to right, #ff495c, #ff692f);
    color: white;
  }

  &--shadow {
    box-shadow: 2px 2px 6px 0 rgba(63, 63, 63, 0.5);
  }
}

.section-header {
  padding-top: 8px;
  padding-bottom: 40px;
  // padding-inline: 8px;
  // margin-inline: -16px;
  margin-bottom: -32px;

  &__title {
    margin: 0;
    text-align: center;
    font-size: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--blue {
    background-color: var(--ion-color-primary);;

    .section-header__title {
      color: white;
    }
  }

  &--sky {
    background-image: linear-gradient(to right, #42a3ff, #90ebff);

    .section-header__title {
      color: var(--ion-color-primary);;
    }
  }

  &--lime {
    background-image: linear-gradient(to right, #37d3ca, #99e9a1);

    .section-header__title {
      color: var(--ion-color-primary);;
    }
  }

  &--sun {
    background-image: linear-gradient(to right, #ff8c4a, #ffb643);

    .section-header__title {
      color: var(--ion-color-primary);;
    }
  }
}
