.custom-autocomplete-wrapper,
.custom-dd-wrapper {
    position: relative;
    background-color: var(--ion-color-light);
    height: 55px;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom: solid 1px #6d7278;
    padding: 8px;
    margin: 12px 0;

    .custom-autocomplete-dd-btn,
    .custom-dd-btn-control {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        ion-icon {
            position: absolute;
            left: 0;
            top: calc(50% - 12px);
            height: 24px;
            width: 24px;
        }
        .custom-dd-hdr {
            color: black;
        }
    }

    &.show-label {
        &>div {
            width: 100%;
            height: calc(100% - 12px);
        }
    }
    ion-label {
        color: black;
        font-size: 12px;
    }

    &>div {
        width: 100%;
        height: 100%;
    }

    input {
        width: inherit;
        height: 100%;
        background-color: inherit;
        border: 0;
        color: black;
        font-size: 16px;
        
        &:focus {
            border: 0;
            outline: none;
        }
        &::-webkit-input-placeholder { 
            color: black;
          }
          &::-moz-placeholder { 
            color: black;
          }
          &:-ms-input-placeholder { 
            color: black;
          }
          &:-moz-placeholder { 
            color: black;
          }
    }

    .custom-autocomplete-dd-list,
    .custom-dd-list-inner {

        position: absolute;
        right: 0;
        top: 70px;
        background-color: white;
        width: 100%;
        z-index: 100;
        padding: 0 8px;
        border-radius: 11px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.17);

        .checkbox-wrapper {
            display: flex;
            align-items: center;
            margin: 8px;
        }

        ion-checkbox {
            margin: 12px;
        }
    }

    .custom-dd-list {
        display: none;

        &.active {
            display: block;
        }
    }
}
.self-sale-card-areas {
    .custom-radio-group .item-radio {
        width: 24%;
    }
    &.self-sale-card-mobile {
        .custom-radio-group .item-radio {
            width: 100%;
        }
    }
}
