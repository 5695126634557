@import "../../theme/maps.scss";

.share-popup {
  .action-sheet-group {
    display: flex;
    padding: 34px 0 10px;
    height: 114px;
  }

  .action-sheet-button {
    font-size: 12px;
    padding: 0;
    height: 70px;
    background: unset;

    @media (min-width: map-get($grid-breakpoints, sm)) {
      font-size: 14px;
    }

    .action-sheet-button-inner {
      flex-direction: column;
    }

    .action-sheet-icon {
      margin-bottom: 5px;
      height: 25px;
      width: 25px;
      contain: initial;
      border-radius: 25px;
      padding: 7px;
      color: white;
    }

    &.facebook {
      .action-sheet-icon {
        background: #3c5598;
      }
    }
    &.mail {
      .action-sheet-icon {
        background: #e13032;
      }
    }
    &.twitter {
      .action-sheet-icon {
        background: #000;
      }
    }
    &.whatsapp {
      .action-sheet-icon {
        background: #01e675;
      }
    }
    &.sms {
      .action-sheet-icon {
        background: #9c27b0;
      }
      @media (min-width: map-get($grid-breakpoints, sm)) {
        display: none;
      }
    }
    &.qr {
      .action-sheet-icon {
        background: var(--ion-color-primary);
      }
    }

    &.native-hide {
      display: none;
    }
  }

  .action-sheet-title {
    position: absolute;
    bottom: 80px;
    right: 0;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    color: var(--ion-color-primary);
    background: none;
  }
}

.highlight-section {
  text-align: center;
  background: #f5f5f5;
  padding: 12px 11px 18px;
  margin: 16px 0;

  h3 {
    font-weight: bold;
    margin: 0 0 7px 0;
    line-height: 19.2px;
    padding: 0 20px;
  }
}

.icon-text-para {
  display: flex;
  margin-top: 16px;

  ion-icon {
    margin-left: 10px;
    height: 25px;
    width: 50px;
  }
}
